import React from "react";
import MainSection from "../sections/MainSection";
import AuditSection from "../sections/AuditSection";
import BuySection from "../sections/BuySection";
import NewsSection from "../sections/NewsSection";
import GallerySection from "../sections/GallerySection";
import FooterSection from "../sections/FooterSection";
import TokenomicSection from "../sections/TokenomicSection";
import PartnerSection from "../sections/PartnerSection";
import CuckTheGameSection from "../sections/CuckTheGame";
import { Outlet } from "react-router-dom";
import {Helmet} from "react-helmet";

const LandingPage = () => {

    /*npm

    MainSection : Intro Cuck
    Buy Cuck
    News
    Memes
    Tokenomic
    

    */

    return(
        <>  {/*
            <div>
                <CuckTheGameSection/>
                <MainSection/>
                <AuditSection/>
                <TokenomicSection/>
                <BuySection/>
                <GallerySection/>
                <PartnerSection/>
                <NewsSection/>
                <FooterSection/>
            </div>
        */}
            <div>
                <CuckTheGameSection/>
                <MainSection/>
                <TokenomicSection/>
                <GallerySection/>
                <FooterSection/>
            </div>
        </>
    )
}

export default LandingPage;
import React, { useContext, useState, useEffect } from "react";
import { Navbar, Nav, Button, Row, Col, Modal } from 'react-bootstrap';
import Logo from '../asset/cuck_logo_400x400.png';
import NavbarToggle from '../asset/navbar_toggle.svg';
import { Link } from "react-router-dom";
import $ from 'jquery';
import { AppContext } from "../utils/AppContext";

import { Image } from 'react-bootstrap';

import AuditBadge from '../asset/audit-badge.avif';

const CuckTheGameSection = () => {

    const appContext = useContext(AppContext);

    const desktopStyles = {
        contentContainerStyle: {
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            padding: "40px 80px",
            justifyContent: 'center',
            backgroundColor: appContext.colors.primaryWhite
        },

        headlineStyle: {
            textAlign: "center",
            fontSize: "72px",
            fontWeight: "500",
            padding: "0px",
            margin: "0px",
            fontFamily: appContext.fontFamily.wedges,
            color: appContext.colors.primaryBlack
        },

        subtitleStyle: {
            textAlign: "center",
            fontSize: "22px",
            fontWeight: "700",
            fontFamily: appContext.fontFamily.inter,
            color: appContext.colors.primaryBlack
        },

        downloadButtonStyle: {
            textDecoration: "none",
            textAlign: "center",
            fontSize: "16px",
            fontWeight: "300",
            margin: "20px",
            padding: "18px 100px",
            lineHeight: "normal",
            borderRadius: "50px",
            fontFamily: appContext.fontFamily.wedges,
            color: appContext.colors.primaryWhite,
            backgroundColor: appContext.colors.primaryOrange,
        },

        imageAsset1: {
            width: "50%",
            padding: "0px"
        },

        socialsIcon: {
            width: (window.innerWidth/20)+"px",
            padding: "0px",
            marginTop: '20px',
        },

        logoContainerStyle: {
            display: 'flex',
            gap: '30px',
            justifyContent: 'center',
            alignItems: 'center',
        } 
    }

    const mobileStyles = {
        contentContainerStyle: {
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: 'center',
            padding: "30px 60px",
            backgroundColor: appContext.colors.primaryWhite
        },

        headlineStyle: {
            textAlign: "center",
            fontSize: "30px",
            fontWeight: "500",
            padding: "0px",
            margin: "0px",
            fontFamily: appContext.fontFamily.wedges,
            color: appContext.colors.primaryBlack
        },

        subtitleStyle: {
            textAlign: "center",
            fontSize: "14px",
            fontWeight: "700",
            fontFamily: appContext.fontFamily.inter,
            color: appContext.colors.primaryBlack
        },

        downloadButtonStyle: {
            width: "50%",
            textDecoration: "none",
            textAlign: "center",
            fontSize: "16px",
            fontWeight: "300",
            margin: "20px",
            padding: "18px 32px",
            lineHeight: "normal",
            borderRadius: "50px",
            fontFamily: appContext.fontFamily.wedges,
            color: appContext.colors.primaryWhite,
            backgroundColor: appContext.colors.primaryOrange,
        },

        imageAsset1: {
            width: "90%",
            padding: "0px"
        },

        socialsIcon: {
            width: (window.innerWidth/10)+"px",
            padding: "0px",
            marginTop: '20px',
        },

        logoContainerStyle: {
            display: 'flex',
            gap: '10px',
            alignItems: 'center',
            justifyContent: 'center',
        }

    }

    const styles = appContext.mobileMode ? mobileStyles : desktopStyles

    return (
        /*<>
            <div style={styles.contentContainerStyle}>
                <h1 style={styles.headlineStyle}>Cuck The Game is Live!</h1>
                <Link style={styles.downloadButtonStyle} onClick={() => {window.open("https://cuckthegame.com/", '_blank').focus();}}>
                    Play Now!
                </Link>

            </div>
        </>*/
        <>
            <div style={styles.contentContainerStyle}>
                <h1 style={styles.headlineStyle}>Buy on Pinksale Now!</h1>
                <Link style={styles.downloadButtonStyle} onClick={() => {window.open("https://www.pinksale.finance/solana/launchpad/4HbkGyShUAA5cNc6U3fV2gvd2FYX11KGSmAsEHQsVBb2", '_blank').focus();}}>
                    Buy $CUCK
                </Link>

            </div>
        </>
    )
}

export default CuckTheGameSection;